@tailwind base;
@tailwind components;
@tailwind utilities;

@import "fonts.css";
@import "~swiper/css";
@import "~swiper/css/navigation";
@import "~swiper/css/pagination";

@layer base {
  body {
    @apply font-sans text-default;
  }

  .text-shadow-bold {
    text-shadow: 1px 0 currentColor;
  }
}

@layer components {
  .prose {
    @apply max-w-3xl 
      mx-auto
      prose-h2:text-4xl
      prose-h2:font-important
      prose-h2:font-normal
      prose-h2:mb-8
      prose-h3:text-xl
      prose-h3:font-important
      prose-h3:font-medium
      prose-h3:text-primary
      prose-h3:mb-8
      prose-h4:text-xl
      prose-h4:font-important
      prose-h4:mb-8
      prose-p:mb-8
      prose-ul:pl-0
      prose-li:list-none
      prose-li:before:content-['\25A0']
      prose-li:before:text-primary
      prose-li:before:mr-4;
  }

  .prose-white {
    @apply prose-li:before:text-white;
  }
}

@layer utilities {
  .aspect-a4 {
    position: relative;
    width: 100%;
    padding-bottom: 141.42%;
  }
}

.prose h3 strong {
  @apply text-primary;
}

.prose strong.highlighted {
  @apply text-xl font-important font-medium;
}

.prose blockquote {
  @apply relative bg-primary p-8 lg:p-16 border-none rounded-md text-white prose-strong:text-white;
}

.prose a {
  @apply underline-offset-4 decoration-primary hover:text-primary visited:text-primary-dark visited:hover:text-primary transition;
}

.prose.prose-white a {
  @apply underline-offset-4 decoration-white hover:text-white visited:text-white visited:hover:text-white transition;
}

.prose a.button {
  @apply inline-block py-5 px-6 rounded-md border font-important font-medium no-underline transition-colors duration-500;
}

.prose a.button.button--primary:not(.inverse) {
  @apply border-primary bg-primary text-white hover:border-primary-dark hover:bg-primary-dark;
}

.prose a.button.button--primary.inverse {
  @apply border-primary bg-white text-default hover:bg-primary hover:text-white;
}

.prose p:first-child {
  margin-top: 0;
}

.prose p:last-child {
  margin-bottom: 0;
}

.prose-white {
  @apply text-white;
}

.prose-white a {
  @apply text-white underline underline-offset-4 decoration-primary hover:text-primary visited:text-secondary-light visited:hover:text-primary transition-colors;
}

/* reCaptcha */
.grecaptcha-badge {
  visibility: hidden;
  right: 0 !important;
}

/* Cookie though */
.cookie-though {
  --ct-primary-400: theme("colors.primary.DEFAULT");
  --ct-primary-300: theme("colors.primary.DEFAULT");
  --ct-primary-200: theme("colors.primary.DEFAULT");
  --ct-primary-100: theme("colors.secondary.light");
  --ct-primary-font: theme("fontFamily.sans");
  --ct-secondary-font: theme("fontFamily.sans");
  --ct-slider-primary: theme("colors.primary.DEFAULT");
  --ct-slider-secondary: theme("colors.secondary.DEFAULT");
  --ct-slider-enabled-primary: white;
  --ct-slider-enabled-secondary: theme("colors.secondary.DEFAULT");
  --ct-button-padding: 0.5rem 1rem;
  --ct-button-radius: 0;

  border-radius: 0;
  line-height: 1.4;
}
